import React from 'react';
import IndexLayout from '@layouts/index';
import Footer from '@components/Footer';
import Header from '@components/Header';
import Intro from '@page-components/contact-sales/Intro';
import RequestDemo from '@page-components/contact-sales/RequestDemo';
import FormSection from '@page-components/contact-sales/FormSection';

const Contact: React.FC = () => {
  return (
    <IndexLayout
      pageName="contact-sales"
      helmet={{
        title: 'Contact Sales - TileDB',
        description: "Talk with our sales team. We're always here to help. Let's get in touch.",
      }}
    >
      <Header />
      <main className="main">
        <Intro />
        <FormSection />
        <RequestDemo />
      </main>
      <Footer />
    </IndexLayout>
  );
};

export default Contact;
